<template>
  <div class="page_box">
    <div class="main_box">
      <div class="main-shop">
        <span class="main-shop-label">门店:</span>
        <el-select v-model="shopId" @change="shopChange" size="medium" class="select" placeholder="请选择门店">
          <el-option v-for="{name,id} in shopList" :value="id" :label="name" :key="id"></el-option>
        </el-select>
      </div>
      <el-button v-rights="'athena:license:add'" @click="getLicense" size="medium" class="button" type="primary">生成授权码</el-button>
      <div v-if="licenseInfo.code" class="license-code">
        <p>
          <span style="vertical-align: text-bottom">授权码：</span>
          <i class="code" v-for="(item, index) in licenseInfo.code" :key="index">{{ item }}</i>
          <i @click="copyCode" class="el-icon-document-copy copy" title="复制"></i>
        </p>
        <p class="tip">有效期：
          <Statistic
            :value="expireTime"
            format="m 分 s 秒"
          />
        </p>
      </div>
    </div>

    <div class="page-box-overflow">

      <a-table
        class="license-table"
        rowKey="code"
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        bordered
        :scroll="{ y: 'calc(100% - 39px)' }"
      >
        <template slot="expireTimeStr" slot-scope="text, row">
          <span>
            {{ text }}
            <Tag size="small" :type="row.expireTimeTagType" style="margin-left: 10px">{{ row.expireTimeTag }}</Tag>
          </span>
        </template>
      </a-table>
    </div>

    <GTablePagination :total="total" @changePage="pageInfo => getPage(pageInfo)" />

  </div>
</template>

<script>
  import GTablePagination from '@/components/GTablePagination'
  import { Statistic } from 'ant-design-vue'
  import { Tag } from 'element-ui'
  import moment from 'moment'
  export default {
    name: 'Customer',
    components: {
      GTablePagination,
      Statistic: Statistic.Countdown,
      Tag
    },
    data () {
      return {
        expireTime: '',
        columns: [
          {
            title: '序号',
            key: 'index',
            customRender: (text, record, index) => index + 1,
            width: 60,
            align: 'center'
          },
          {
            title: '门店',
            dataIndex: 'shopName',
          },
          {
            title: '授权码',
            dataIndex: 'code',
          },
          {
            title: '过期时间',
            dataIndex: 'expireTimeStr',
            scopedSlots: { customRender: 'expireTimeStr' }
          },
          {
            title: '创建人',
            dataIndex: 'createOperatorName',
          },
          {
            title: '创建时间',
            dataIndex: 'createTimeStr'
          }
        ],
        shopId: '',
        shopList: [],
        licenseInfo: {},
        tableData: [],
        total: 0,
        pageSize: 20,
        expireTimeNum: 0,
        expireTimeFn: null
      }
    },
    created () {
      this.getShopList()
      this.getPage({ page: 1, pageSize: this.pageSize })
    },
    methods: {
      async getShopList () {
        const response = await this.$axios.get('/hera/shop/list')
        this.shopList = response.data
        this.shopId = this.shopList[0].id
      },
      getPage(pageInfo) {
        this.pageSize = pageInfo.size
        this.page = pageInfo.page
        this.$axios.get('/athena/license/page', {
          page: this.page,
          size: this.pageSize
        }).then(res => {
          res.data.list.forEach(v => {
            v.expireTimeTag = moment(v.expireTime).diff(moment()) > 0 ? '未过期' : '已过期'
            v.expireTimeTagType = moment(v.expireTime).diff(moment()) > 0 ? 'success' : 'info'
          })
          this.total = res.data.total
          this.tableData = res.data.list
        })
      },
      shopChange () {
        this.licenseInfo = {}
      },
      async getLicense () {
        const { shopId } = this
        if (!shopId) {
          this.$message.warning('请先选择门店！')
          return
        }
        const response = await this.$axios.post('/athena/license/add', { shopId })
        if (response.code === '0000') {
          this.$message.success('授权码生成成功')
          this.licenseInfo = response.data
          this.getPage({ page: 1, pageSize: this.pageSize })
          this.expireTime = new Date(response.data.expireTimeStr)
        }
      },
      copyCode () {
        let transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = this.licenseInfo.code
        transfer.focus()
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$message.success('复制成功')
      }
    }
  }
</script>

<style scoped lang="scss">
  .page_box {
    width: 100%;
    height: 100%;
    padding: 12px 0 12px 12px;
    border: 1px solid transparent;
    position: relative;
    overflow: hidden;
    .page-box-overflow{
      border-radius: 4px;
      overflow: hidden;
      width: 100%;
      height: calc(100% - 120px);
      overflow-y: auto;
      margin-top: 12px;
      box-shadow: $cardShadow;
      background: #fff;
    }
  }
  .main_box {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    align-items: center;
    box-shadow: $cardShadow;
    .main-shop{
      .main-shop-label{
        display: inline-block;
        width: 50px;
        font-size: 14px;
        color: $title1;
      }
    }
    .select {
      width: 220px;
    }
    .button {
      margin: 0 12px;
    }
    .license-code{
      font-size: 16px;
      font-weight: bold;
      margin-left: 48px;
      display: flex;
      align-items: center;
      .ant-statistic{
        display: inline-block;
        vertical-align: sub;
      }
      .code{
        padding: 4px 10px;
        border: 1px #f1f0f0 solid;
        font-size: 22px;
        font-weight: bold;
        border-radius: 4px;
        margin-left: 4px;
        color: #409EFF;
      }
    }
    .copy {
      cursor: pointer;
      font-size: 20px;
      vertical-align: baseline;
      margin: 0 12px;
    }
    .tip {
      font-size: 14px;
      font-weight: normal;
      margin-left: 20px;
    }
  }
  .license-table{
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    ::v-deep{
      .ant-spin-nested-loading{
        width: 100%;
        height: 100%;
        .ant-spin-container{
          height: 100%;
          .ant-table{
            height: 100%;
            .ant-table-content{
              height: 100%;
              .ant-table-scroll{
                height: 100%;
                .ant-table-thead{
                  tr{
                    th{
                      padding: 8px;
                    }
                  }
                }
                .ant-table-tbody{
                  tr{
                    td{
                      padding: 8px;
                    }
                  }
                }
                .ant-table-body{
                  margin: 0;
                }
              }
            }
          }
        }
      }
      .ant-table-placeholder{
        border-bottom: none;
      }
      .ant-table-column-title{
        color: #475669;
        font-size: 12px;
        border-color: #EBEEF5;
      }
    }
  }
  .el-select-dropdown__item{
    padding: 0 12px;
  }
</style>
